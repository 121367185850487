@font-face {
  font-family: 'Gotham Bold';
  src: url('/assets/fonts/Gotham-Bold.woff2') format('woff2'),
  url('/assets/fonts/Gotham-Bold.woff') format('woff'),
  url('/assets/fonts/Gotham-Bold.ttf') format('truetype'),
  url('/assets/fonts/Gotham-Bold.svg#Gotham-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Gotham Book';
  src: url('/assets/fonts/Gotham-Book.woff2') format('woff2'),
  url('/assets/fonts/Gotham-Book.woff') format('woff'),
  url('/assets/fonts/Gotham-Book.ttf') format('truetype'),
  url('/assets/fonts/Gotham-Book.svg#Gotham-Book') format('svg');
  font-weight: normal;
  font-style: normal;
}

