@charset "UTF-8";

@font-face {
  font-family: "buildernow";
  src:url("/assets/fonts/buildernow.eot");
  src:url("/assets/fonts/buildernow.eot?#iefix") format("embedded-opentype"),
  url("/assets/fonts/buildernow.woff") format("woff"),
  url("/assets/fonts/buildernow.ttf") format("truetype"),
  url("/assets/fonts/buildernow.svg#buildernow") format("svg");
  font-weight: normal;
  font-style: normal;

}

[data-icon]:before {
  font-family: "buildernow", Arial, Georgia,serif !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="icon-"]:before,
[class*=" icon-"]:before {
  font-family: "buildernow", Arial, Georgia,serif !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-magnifying:before {
  content: "\61";
}
.icon-sliders:before {
  content: "\62";
}
.icon-angle-down:before {
  content: "\63";
}
.icon-angle-left:before {
  content: "\64";
}
.icon-angle-right:before {
  content: "\65";
}
.icon-angle-up:before {
  content: "\66";
}
.icon-cancel:before {
  content: "\67";
}
.icon-dot-3:before {
  content: "\68";
}
.icon-menu:before {
  content: "\69";
}
.icon-upload:before {
  content: "\6a";
}
.icon-phonescreen:before {
  content: "\6b";
}
.icon-webscreen:before {
  content: "\6c";
}
.icon-share-outline:before {
  content: "\6d";
}
.icon-prototype:before {
  content: "\6f";
}
.icon-crossnew:before {
  content: "\70";
}
.icon-drag:before {
  content: "\71";
}
.icon-close-circled:before {
  content: "\72";
}
.icon-in:before {
  content: "\73";
}
.icon-folder:before {
  content: "\74";
}
.icon-view:before {
  content: "\75";
}
.icon-android-add:before {
  content: "\76";
}
.icon-android-remove:before {
  content: "\77";
}
.icon-iconshare:before {
  content: "\78";
}
.icon-android-contract:before {
  content: "\79";
}
.icon-android-expand:before {
  content: "\7a";
}
.icon-external-link:before {
  content: "\41";
}
.icon-square-o:before {
  content: "\43";
}
.icon-circle-thin:before {
  content: "\42";
}
.icon-iconcross:before {
  content: "\44";
}
.icon-left-arrow:before {
  content: "\45";
}
.icon-mail:before {
  content: "\46";
}
.icon-dashboard:before {
  content: "\47";
}
.icon-flowchartnew:before {
  content: "\48";
}
.icon-windows:before {
  content: "\49";
}
.icon-play:before {
  content: "\4a";
}
.icon-combined:before {
  content: "\4b";
}
.icon-deletenew:before {
  content: "\4c";
}
.icon-link:before {
  content: "\4d";
}
.icon-arrow-left:before {
  content: "\4e";
}
.icon-tick:before {
  content: "\4f";
}
.icon-webnew:before {
  content: "\50";
}
.icon-mobilenew:before {
  content: "\51";
}
.icon-searchnew:before {
  content: "\52";
}
.icon-ticknew:before {
  content: "\53";
}
.icon-reload:before {
  content: "\54";
}
.icon-comment:before {
  content: "\55";
}
.icon-desktop:before {
  content: "\56";
}
.icon-download:before {
  content: "\57";
}
.icon-bin:before {
  content: "\59";
}
.icon-link-1:before {
  content: "\5a";
}
.icon-guest:before {
  content: "\31";
}
.icon-zoom-out1:before {
  content: "\37";
}
.icon-comment-de:before {
  content: "\32";
}
.icon-play-1:before {
  content: "\33";
}
.icon-eye:before {
  content: "\35";
}
.icon-info-circle:before {
  content: "\58";
}
.icon-info-circle-1:before {
  content: "\36";
}
.icon-view-change:before {
  content: "\38";
}
.icon-user-flow:before {
  content: "\39";
}
.icon-tick-1:before {
  content: "\21";
}
.icon-show-flow:before {
  content: "\22";
}
.icon-plus-1:before {
  content: "\23";
}
.icon-zoom-in:before {
  content: "\24";
}
.icon-desktop-1:before {
  content: "\25";
}
.icon-download-1:before {
  content: "\26";
}
.icon-share-1:before {
  content: "\27";
}
.icon-mobile:before {
  content: "\28";
}
.icon-comment-new:before {
  content: "\2a";
}
.icon-plus:before {
  content: "\2b";
}
.icon-caret-up:before {
  content: "\34";
}
.icon-caret-down:before {
  content: "\2c";
}
.icon-uo:before {
  content: "\2e";
}
.icon-comment-fill:before {
  content: "\2f";
}
.icon-down:before {
  content: "\2d";
}
.icon-right:before {
  content: "\3a";
}
.icon-back-arrow:before {
  content: "\3b";
}
.icon-success-tick:before {
  content: "\3c";
}
.icon-notify:before {
  content: "\3d";
}
.icon-upload-1:before {
  content: "\3e";
}
.icon-comment-1:before {
  content: "\30";
}
.icon-undo:before {
  content: "\40";
}
.icon-redo:before {
  content: "\5b";
}
.icon-multi-screen-1:before {
  content: "\5d";
}
.icon-star-icon:before {
  content: "\5e";
}
.icon-start-icon-fill:before {
  content: "\5f";
}
.icon-star-empty:before {
  content: "\60";
}
.icon-star:before {
  content: "\7b";
}
.icon-icon-tab:before {
  content: "\7c";
}
.icon-icon-watch:before {
  content: "\7d";
}
.icon-icon-bell:before {
  content: "\7e";
}
.icon-icon-read:before {
  content: "\5c";
}
.icon-icon-filter:before {
  content: "\e000";
}
.icon-share:before {
  content: "\e001";
}
.icon-delete:before {
  content: "\e002";
}
.icon-icon-action:before {
  content: "\e003";
}
.icon-icon-long-tap:before {
  content: "\e004";
}
.icon-icon-no-trans:before {
  content: "\e005";
}
.icon-icon-slide-down:before {
  content: "\e006";
}
.icon-icon-panding:before {
  content: "\e007";
}
.icon-icon-slide-left:before {
  content: "\e008";
}
.icon-icon-slide-right:before {
  content: "\e009";
}
.icon-icon-slide-up:before {
  content: "\e00a";
}
.icon-icon-swipe-down:before {
  content: "\e00b";
}
.icon-icon-swipe-left:before {
  content: "\e00c";
}
.icon-icon-swipe-right:before {
  content: "\e00d";
}
.icon-icon-swipe-up:before {
  content: "\e00e";
}
.icon-icon-tap:before {
  content: "\e00f";
}
.icon-icon-transition:before {
  content: "\e010";
}
.icon-icon-flow:before {
  content: "\e011";
}
.icon-centre:before {
  content: "\29";
}
.icon-icon-fade:before {
  content: "\e012";
}
.icon-delete-1:before {
  content: "\e013";
}
.icon-customprototype:before {
  content: "\e014";
}
.icon-edit-1:before {
  content: "\e015";
}
.icon-load:before {
  content: "\e016";
}
.icon-pencil:before {
  content: "\e017";
}
.icon-edit:before {
  content: "\6e";
}
.icon-leftside:before {
  content: "\3f";
}
.icon-calendar:before {
  content: "\e019";
}
.icon-add1:before {
  content: "\e018";
}
.icon-close-thick:before {
  content: "\e01a";
}
.icon-add-designer:before {
  content: "\e01b";
}
.icon-custom-image-icon:before {
  content: "\e01c";
}
