@import 'abstract/variables';
@import 'abstract/mixins';
@import 'abstract/icons';
@import 'abstract/fonts';

@import url( 'https://fonts.googleapis.com/css?family=Roboto:400,700|Material+Icons');
@import '~@angular/material/prebuilt-themes/indigo-pink.css';


*, *:before, *:after {box-sizing: border-box;}
html, body {width:100%; min-height: 100vh; font-family: 'Gotham Book', Arial, Georgia,serif; overflow: hidden; color:$bodytextcolor; font-size: 14px; background: $bodybackground;}
button, input, select {outline: none;}
ul, ol, li, h1, h2, h3, h4, h5, h6 {margin: 0; padding:0; list-style-type: none;}
.maincontainer {width:100%; min-height: 100vh; position: relative;}

.drag-cursor{
  cursor: url(../assets/images/drag-cursor.png), move !important;
}
.mat-button-focus-overlay{
  display: none;
}
